import cn from '@/utils/style';
import { FC, PropsWithChildren } from 'react';

interface AnalysisCardProps extends PropsWithChildren {
  title?: string;
  className?: string;
}

const AnalysisCard: FC<AnalysisCardProps> = ({ title, children, className }) => {
  return (
    <div className={cn('h-[108px] rounded-leap bg-white p-4', className)}>
      <div className='flex flex-col items-start gap-3'>
        <span className='text-xl capitalize'>{title}</span>
        <span className='w-full text-start text-xl text-sky-600'>{children}</span>
      </div>
    </div>
  );
};

export type { AnalysisCardProps };

export default AnalysisCard;
