import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { ProgressBar } from '@/components';
import cn from '@/utils/style';

interface useURLsTableColumnsProps {
  navigateAction: (id: number) => void;
}

const columnHelper = createColumnHelper<LCRURL<User>>();

const statusColor = {
  error: 'bg-red-500',
  in_progress: 'bg-yellow-400',
  completed: 'bg-green-500',
  created: 'bg-sky-500',
};

const statusLabel = {
  error: 'Error',
  in_progress: 'In Progress',
  completed: 'Success',
  created: 'Created',
};

const URLMessage = {
  error: {
    html: 'Something went wrong. Please try again.',
    url_and_description: 'Fetching URL and Description',
    target_audience: 'Fetching Target Audience',
    stakeholders: 'Fetching Stakeholders',
    stakeholder_questions: 'Fetching Stakeholders Question',
    worse_case_scenario: 'Fetching Worse Case Scenario',
    expert_questions: 'Fetching Expert Question',
    questions_summary: 'Fetching Questions Summary',
  },
  in_progress: {
    html: 'Fetching HTML',
    url_and_description: 'Fetching URL and Description',
    target_audience: 'Fetching Target Audience',
    stakeholders: 'Fetching Stakeholders',
    stakeholder_questions: 'Fetching Stakeholders Question',
    worse_case_scenario: 'Fetching Worse Case Scenario',
    expert_questions: 'Fetching Expert Question',
    questions_summary: 'Fetching Questions Summary',
  },
};

const useURLsTableColumns = ({ navigateAction }: useURLsTableColumnsProps) =>
  useMemo(
    () => [
      columnHelper.accessor('url', {
        header: () => <p className='text-left'>URL</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('offering', {
        header: () => <p className='text-left'>Offering</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('buyer_wants_to_achieve', {
        header: () => <p className='text-left'>Want to Achieve</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.display({
        id: 'status',
        header: () => <p className='text-left'>Status</p>,
        cell: ({
          row: {
            original: { status, message, progress },
          },
        }) => (
          <div className='flex flex-col text-left'>
            <p className='flex items-center gap-2'>
              <span className={cn(statusColor[status], 'h-3 w-3 rounded-full')}></span>
              {statusLabel[status]}
            </p>
            {status !== 'error' && status !== 'completed' ? <ProgressBar progress={progress || 0} showProgress={false} color='bg-green-700' className='my-1' /> : null}
            {status !== 'completed' ? <p>{message && (status === 'error' || status === 'in_progress') ? URLMessage[status][message] : ''}</p> : null}
          </div>
        ),
        size: 200,
      }),
      columnHelper.accessor('user', {
        header: () => <p className='text-left'>Created By</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()?.fullName || '-'}</p>,
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <p className='text-right'>Actions</p>,
        cell: ({ row }) => {
          return (
            <div className='flex w-full justify-end gap-4'>
              <button onClick={() => navigateAction(row.original.id)}>
                <ArrowRightIcon className='size-6 text-gray-400' />
              </button>
            </div>
          );
        },
      }),
    ],
    [navigateAction],
  );

export default useURLsTableColumns;
