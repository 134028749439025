import { Table } from '@/components';
import { useTable, useTableHandlersWithFilters } from '@/hooks/table';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useMemo, useState } from 'react';
import useURLsTableColumns from './URLsColumns';
import { useFilterContext } from '@/hooks/filters';
import { useGetURLListQuery } from '@/store/poc/poc.api';
import URLPageSkeleton from '../../URLPageSkeleton';

const PAGE_SIZE = 20;

const URLsTable: FC = () => {
  const [pollingInterval, setPollingInterval] = useState(0);
  const { filters, changePage } = useFilterContext();
  const { handlePaginationChange, pagination } = useTableHandlersWithFilters(filters);
  const { data, isLoading, isFetching } = useGetURLListQuery({ page: filters.page || 1 }, { pollingInterval });
  const navigate = useNavigate();
  const pageCount = useMemo(() => Math.ceil((data?.count || 0) / PAGE_SIZE), [data?.count]);

  const inProgress = useMemo(() => data?.results.some((url) => url.status === 'in_progress' || url.status === 'created'), [data]);

  useEffect(() => {
    if (inProgress) {
      return setPollingInterval(3000);
    }

    setPollingInterval(0);
  }, [inProgress]);

  const columns = useURLsTableColumns({
    navigateAction: (id) => navigate(`url/${id}/detail`),
  });

  const [table] = useTable({
    data: data?.results || [],
    columns,
    tableCustomOptions: ['allowSort'],
    tableOptions: {
      onPaginationChange: handlePaginationChange,
      state: {
        pagination: {
          ...pagination,
          pageSize: PAGE_SIZE,
        },
      },
      manualPagination: true,
      pageCount,
    },
  });

  if (isLoading) {
    <URLPageSkeleton />;
  }

  return (
    <div className='rounded-leap bg-white p-4'>
      <Table
        table={table}
        name='pocUrls'
        totalRows={data?.count || 0}
        containerClass='max-h-[70vh] overflow-y-auto [&>div]:sticky [&>div]:bottom-0'
        tableClass='shadow-none'
        onPageChange={changePage}
        loadingText='Loading URLs...'
        isLoading={isLoading || (isFetching && !inProgress)}
      />
    </div>
  );
};

export default URLsTable;
