import { dateFromString } from '@/utils/dates';
import cn from '@/utils/style';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

interface UseAccessRequestsTableColumnsParams {
  approveAction: (id: string) => void;
  denyAction: (id: string) => void;
}

const columnHelper = createColumnHelper<BetaAccessUser>();

const useAccessRequestsTableColumns = ({ approveAction, denyAction }: UseAccessRequestsTableColumnsParams) =>
  useMemo(
    () => [
      columnHelper.accessor('first_name', {
        header: () => <p className='text-left'>Full Name</p>,
        cell: ({ row }) => {
          const value = `${row.original.first_name} ${row.original.last_name}`;
          return (
            <p className='line-clamp-1 text-ellipsis break-all text-left' title={value}>
              {value}
            </p>
          );
        },
      }),
      columnHelper.accessor('email', {
        header: () => <p className='text-left'>Email</p>,
        cell: ({ getValue }) => (
          <p className='line-clamp-1 text-ellipsis break-all text-left' title={getValue()}>
            {getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('domain', {
        header: () => <p className='text-left'>Domain</p>,
        cell: ({ getValue }) => (
          <p className='line-clamp-1 text-ellipsis break-all text-left' title={getValue()}>
            {getValue()}
          </p>
        ),
        size: 300,
      }),
      columnHelper.accessor('is_agency', {
        header: () => <p className='text-center'>Is Agency</p>,
        cell: ({ getValue }) => <>{getValue() ? <CheckIcon className='size-6 text-green-600' /> : <XMarkIcon className='size-6 text-red-600' />}</>,
        size: 90,
      }),
      columnHelper.accessor('newsletter', {
        header: () => <p className='text-center'>Newsletter</p>,
        cell: ({ getValue }) => <>{getValue() ? <CheckIcon className='size-6 text-green-600' /> : <XMarkIcon className='size-6 text-red-600' />}</>,
        size: 90,
      }),
      columnHelper.accessor('date_created', {
        header: () => (
          <p className='text-left'>
            Date <br /> Created
          </p>
        ),
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
        sortingFn: (a, b) => dateFromString(a.original.date_created).getTime() - dateFromString(b.original.date_created).getTime(),
        size: 160,
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <p className='text-left'>Actions</p>,
        cell: ({ row }) => {
          return (
            <div className={cn('flex w-full justify-end gap-4')}>
              <button onClick={() => approveAction(row.original.id)} disabled={row.getIsSelected()}>
                <CheckIcon className='size-6 text-gray-400' />
              </button>
              <button onClick={() => denyAction(row.original.id)} disabled={row.getIsSelected()}>
                <XMarkIcon className='size-6 text-gray-400' />
              </button>
            </div>
          );
        },
        size: 60,
      }),
    ],
    [approveAction, denyAction],
  );

export default useAccessRequestsTableColumns;
