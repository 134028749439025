import { Button, Modal, ModalProps, SelectField, OrganicResearchModal, AddUrlCombinationURLsManually, TextInputField } from '@/components';

import { KEYWORDS_COUNT, URL_SELECTION_OPTIONS, URLS_COUNT } from '@/constants/defaultValues';
import { useToggle } from '@/hooks/toggle';
import { useGetCampaignQuery } from '@/store/campaign/campaign.api';
import { CombinationSchema } from '@/utils/combinations';
import { FormikProvider, useFormik } from 'formik';
import { FC, Fragment } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

interface AddUrlModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: () => void;
}

const AddUrlModalSchema: yup.SchemaOf<AddUrlFormValues> = yup.object({
  competitors_keywords: yup.number().required('Competitors / Keywords is required'),
  keywords: yup.number().required('Keywords is required'),
  url_selection: yup.mixed<UrlSelection>().required('URL Selection is required'),
  urls_list: yup.string(),
  combinations: yup.array().of(CombinationSchema),
  domain: yup.string().when('url_selection', {
    is: 'from_domain',
    then: yup.string().required('A domain or URL is required'),
  }),
});

const AddUrlModal: FC<AddUrlModalProps> = ({ isOpen, onClose }) => {
  const { value: isOrganicResearchModalOpen, toggle: toggleOrganicResearchModal } = useToggle();
  const { value: isUrlManuallyModalOpen, toggle: toggleAddUrlsManually } = useToggle();
  const { campaignId } = useParams() as { campaignId: string };
  const { data: currentCampaignData, isLoading } = useGetCampaignQuery({ campaignId, include: ['client_domain', 'country'] });

  const handleSubmit = (values: AddUrlFormValues) => {
    const { url_selection } = values;

    if (url_selection === 'from_domain') {
      return toggleOrganicResearchModal();
    }

    if (url_selection === 'urls_kws_manually') {
      return toggleAddUrlsManually(true);
    }
  };

  const formik = useFormik<AddUrlFormValues>({
    onSubmit: handleSubmit,
    initialValues: {
      competitors_keywords: URLS_COUNT[0].value,
      keywords: KEYWORDS_COUNT[0].value,
      url_selection: URL_SELECTION_OPTIONS[0].value,
      urls_list: '',
      combinations: [{ url: '', keywords: [] }],
      domain: '',
    },
    validationSchema: AddUrlModalSchema,
  });

  return (
    <Fragment>
      <Modal isOpen={isOpen} title={'Add Combination'} onClose={onClose}>
        <FormikProvider value={formik}>
          <div className='flex flex-nowrap items-center gap-2'>
            <div className='flex w-96 flex-col gap-4'>
              <SelectField label='Competitors / Keywords' name='competitors_keywords' options={URLS_COUNT} />
              <SelectField label='Keywords' name='keywords' options={KEYWORDS_COUNT} />
              <SelectField label='URL Selection' name='url_selection' options={URL_SELECTION_OPTIONS} />
              {formik.values.url_selection === 'from_domain' && <TextInputField label='Domain / URL' name='domain' />}
            </div>
          </div>
          {isOrganicResearchModalOpen && currentCampaignData && (
            <OrganicResearchModal
              name='urls_list'
              isLoading={isLoading}
              onConfirm={() => toggleAddUrlsManually(true)}
              isOpen={isOrganicResearchModalOpen}
              onClose={() => toggleOrganicResearchModal(false)}
              country={currentCampaignData.country || 'us'}
              url={formik.values.domain || ''}
            />
          )}
          {isUrlManuallyModalOpen && (
            <AddUrlCombinationURLsManually
              isOpen={isUrlManuallyModalOpen}
              onClose={() => {
                toggleAddUrlsManually(false);
                onClose && onClose();
              }}
            />
          )}
        </FormikProvider>
        <div className='mt-4 flex w-full items-center gap-2 pt-4'>
          <Button className='w-full' variant='outline-light' onClick={onClose}>
            Cancel
          </Button>
          <Button className='w-full' onClick={formik.submitForm}>
            Confirm
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AddUrlModal;
