import { Loading, Modal } from '@/components';
import { FC, useCallback } from 'react';
import { getDateWithFormat, secondsToTime } from '@/utils/dates';
import { useGetCampaignQuery } from '@/store/campaign/campaign.api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@/store/auth/auth.selector';

interface LogModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const servicesSlugs: Array<keyof ApiCallsData> = ['moz', 'semrush', 'dataforseo', 'openai'];

const CampaignLogModal: FC<LogModalProps> = ({ isOpen, onClose }) => {
  const user = useSelector(selectAuthUser);
  const { campaignId } = useParams() as { campaignId: string };
  const { data: campaign, isLoading } = useGetCampaignQuery({ campaignId, include: ['runs', 'log'] });
  const formatEntry = useCallback((entry: string) => {
    const parts = entry.split(' | ');

    if (parts.length !== 2) {
      return entry;
    }

    return `${getDateWithFormat(parts[0], 'dd MMM  HH:mm:ss')} => ${parts[1]}`;
  }, []);

  const lastRun = campaign?.runs.at(-1);
  const apiCallsData: ApiCallsData | null = lastRun ? lastRun.stats.api_calls : null;

  const getStatsText = useCallback(
    (service: keyof ApiCallsData, index: number) => {
      if (!service || !apiCallsData) {
        return '';
      }

      const { count, time, cost, tokens } = apiCallsData[service];

      return (
        <p key={index} className='text-sm'>
          <b className='capitalize'>{service}</b>: {count} [{time ? secondsToTime(time) : '?'}] {cost ? `$${cost}` : ''} {tokens ? `${tokens} Tokens` : ''}
        </p>
      );
    },
    [apiCallsData],
  );

  return (
    <Modal isOpen={isOpen} title='Logs' onClose={onClose} className='pt-2'>
      {isLoading && (
        <div className='grid place-content-center'>
          <Loading />
        </div>
      )}
      {lastRun && (
        <div>
          <p className='text-sm'>API Calls</p>
          {user?.isSuperuser && servicesSlugs.map(getStatsText).filter(Boolean)}
        </div>
      )}
      <div className='mt-4'>
        {!campaign?.log.length && !isLoading && <div className='mt-4'>No logs.</div>}
        {campaign?.log.map((entry: string, index: number) => {
          return (
            <p key={index} className='my-1'>
              {formatEntry(entry)}
            </p>
          );
        })}
      </div>
    </Modal>
  );
};

export default CampaignLogModal;
