import { getLinksToBuild } from './urls';

const calculateScenarioCost = (linksToBuild: number, selectedScenario: Scenario, company: Company | null) => {
  if (!company) {
    return undefined;
  }

  const matchesTier = company.config.default_cost_tiers.some((tier) => selectedScenario?.cost_per_link === tier.cost);

  const scenarioHasOwnCostPerLink = selectedScenario?.cost_per_link !== company.config.default_cost_per_link && !matchesTier;

  if (scenarioHasOwnCostPerLink) {
    return undefined;
  }

  if (company.config.default_cost_selected === 'static') {
    return company.config.default_cost_per_link;
  }

  const newCustomCost = company.config.default_cost_tiers.find((tier) => {
    const validFrom = linksToBuild >= tier.from;
    const validTo = tier.to === '' || linksToBuild <= Number(tier.to);
    return validFrom && validTo;
  })?.cost;

  return newCustomCost;
};

const formatScenario = (scenario: Scenario, campaignAnalysis: AnalysisData[], company: Company | null) => {
  const linksPerMonth = Math.ceil(
    scenario.urls.reduce((links: number, url) => {
      const campaignAnalysisUrl = campaignAnalysis.find((caUrl) => caUrl.targetPage === url.target_page);
      if (!campaignAnalysisUrl) {
        return links;
      }

      if (url.links_to_build) {
        return links + url.links_to_build;
      }

      if (url.close_to_gap) {
        return links + Number(getLinksToBuild({ closeToGap: url.close_to_gap, lrdGap: campaignAnalysisUrl.lrdGap, projLength: scenario.proj_length }));
      }

      return links;
    }, 0),
  );

  const newScenario = { ...scenario, links_per_month: linksPerMonth };
  const newValue = calculateScenarioCost(linksPerMonth, scenario, company);

  if (newValue) {
    newScenario.cost_per_link = newValue;
  }

  return newScenario;
};

const areScenariosValid = (scenarios: Scenario[]) => {
  return scenarios.every((scenario) => {
    if (!scenario.urls || !scenario.urls.length) {
      return false;
    }

    return scenario.urls.every((url) => url.links_to_build > 0);
  });
};

const getScenariosKeywordTableData = (keywords: RefineCampaignKeyword[], allocation: AllocationKeyword[]): ScenarioRefineKeyword[] => {
  if (!keywords) {
    return [];
  }

  return keywords.map((kw) => {
    const kwAlloc = allocation.find((k) => k.id === kw.id);

    return {
      id: kwAlloc?.id || kw.id,
      keyword: kwAlloc?.keyword || kw.text,
      targetPage: kwAlloc?.targetPage || kw.target_page,
      anchorsTotal: kwAlloc?.anchorsTotal || 0,
      priority: kwAlloc?.priority || 0,
      difficulty: kw.difficulty,
      rank: kw.campaign_rank,
      totalVolume: kwAlloc?.totalVolume || 0,
      lrdGapKeyword: kwAlloc?.lrdGapKeyword || 0,
      rootDomainDiff: kwAlloc?.rootDomainDiff || 0,
      text_hash: kw.text_hash,
    };
  });
};

export { formatScenario, areScenariosValid, getScenariosKeywordTableData };
