import { BASE_API_URL } from '@/constants/application';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    'campaign',
    'campaignAnalysis',
    'campaignCompetitorsDomains',
    'campaignKeywords',
    'campaignList',
    'campaignScenarios',
    'campaignConfig',
    'companiesList',
    'countryList',
    'urlAnchorText',
    'usersList',
    'userNotifications',
    'companyUsersList',
    'currentUser',
    'paymentMethod',
    'subscriptionList',
    'linkLaunchProductPrices',
    'invoiceList',
    'refineKeywords',
    'betaAccessRequests',
  ],
  endpoints: () => ({}),
});

export default api;
