import { Button, Skeleton, CompetitorsModal } from '@/components';
import { Page } from '@/layouts';
import { selectCampaign, selectCampaignAnalysis, selectSelectedScenario } from '@/store/campaign/campaign.selector';
import { getOverviewTableData } from '@/utils/campaigns';
import { FC, Fragment, Suspense, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { AddKeywordModal, AnalysisCardList, KeywordsAnalysisTable } from './components';
import { useToggle } from '@/hooks/toggle';
import {
  useGetRefineKeywordsQuery,
  useLazyGetCampaignAnalysisQuery,
  useLazyGetCampaignQuery,
  useLazyGetKeywordAnalysisQuery,
  useRefineCampaignCompetitorsDomainsMutation,
} from '@/store/campaign/campaign.api';
import { round } from '@/utils/numbers';
import store from '@/store';
import { analysisPageFetchInitiate } from '../AnalysisPage/AnalysisPage';
import AnalysisCardListSkeleton from './components/AnalysisCardList/AnalysisCardListSkeleton';

const IndividualTargetUrlPageLoader: LoaderFunction = async ({ params }) => {
  const { campaignId, targetPageId } = params as AnalyisisPageParams & { targetPageId: string };
  const authUser = store.getState().auth.user;
  if (!authUser?.company) {
    return null;
  }

  const [companyPromise, campaignPromise, scenariosPromise, campaignAnalysisPromise] = analysisPageFetchInitiate({
    campaignId,
    companyId: authUser.company.id,
  });

  try {
    const data = Promise.all([companyPromise.unwrap(), campaignPromise.unwrap(), scenariosPromise.unwrap(), campaignAnalysisPromise.unwrap()]);

    return defer({
      data,
    });
  } catch (e: unknown) {
    return {
      campaignId,
      targetPageId,
      error: e as APIError,
    };
  } finally {
    companyPromise.unsubscribe();
    campaignPromise.unsubscribe();
    scenariosPromise.unsubscribe();
    campaignAnalysisPromise.unsubscribe();
  }
};

const IndividualTargetUrlPage: FC = () => {
  const { targetPageId, campaignId } = useParams() as {
    targetPageId: string;
    campaignId: string;
  };

  const { data } = useLoaderData() as PageLoaderDefData<[unknown, APIListResponse<RefineCampaignKeyword>]>;

  const [fetchCampaign] = useLazyGetCampaignQuery();
  const [fetchCampaignAnalysis] = useLazyGetCampaignAnalysisQuery();
  const [refine] = useRefineCampaignCompetitorsDomainsMutation();
  const [fetchKeywordsAnalysis] = useLazyGetKeywordAnalysisQuery();

  const { value: isAddKeywordModalOpen, toggle: toggleAddKeywordModal } = useToggle();
  const { value: isRefineCompetitorsModalOpen, toggle: toggleRefineCompetitorsModal } = useToggle();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate('../analysis'), [navigate]);

  const campaignAnalysis = useSelector(selectCampaignAnalysis);
  const selectedScenario = useSelector(selectSelectedScenario);
  const campaign = useSelector(selectCampaign);

  const targetPageUrl = useMemo(
    () => getOverviewTableData({ scenarioUrls: selectedScenario?.urls, campaignAnalysis: campaignAnalysis }).find((url) => url.targetPageId === Number(targetPageId)),
    [selectedScenario?.urls, campaignAnalysis, targetPageId],
  );

  const { isLoading, isFetching } = useGetRefineKeywordsQuery({ campaignId, excluded_domains: false, excluded_keywords: true, url_id: targetPageId });

  const handleAddKeyword = () => {
    toggleAddKeywordModal(false);
  };

  const isLoadingAddURL = useMemo(() => {
    if (!campaign) {
      return false;
    }

    if (campaign.last_run_status === 'queued') {
      return true;
    }

    if (campaign.last_run_status === 'error') {
      return false;
    }

    return !['done', 'created', 'kicked_off'].includes(campaign.status);
  }, [campaign]);

  const refreshCampaign = useCallback(async () => {
    if (!campaign) {
      return true;
    }

    const anchorsQueued = campaign.anchors_generation_status && !['error', 'done'].includes(campaign.anchors_generation_status);
    const fetchQueued = campaign.last_run_status === 'queued';

    if (['done', 'created', 'kicked_off'].includes(campaign.status) && !anchorsQueued && !fetchQueued) {
      return true;
    }

    const { status } = await fetchCampaign({ campaignId: String(campaign.id) }).unwrap();

    if (status === 'done' && !anchorsQueued && !fetchQueued) {
      const analysisCampaignPromise = fetchCampaignAnalysis({
        campaignId: String(campaign.id),
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
      }).unwrap();

      const keywordsAnalysisPromise = fetchKeywordsAnalysis({ campaignId: String(campaign.id), urlId: targetPageId }).unwrap();

      await Promise.all([analysisCampaignPromise, keywordsAnalysisPromise]);
    }
  }, [campaign, fetchCampaign, fetchCampaignAnalysis, fetchKeywordsAnalysis, targetPageId]);

  const handleCompetitorsConfirm = async ({ id, domains }: { id: string; domains: Array<CampaignCompetitorDomain> }) => {
    toggleRefineCompetitorsModal(false);

    await refine({ id, domains });

    if (campaign) {
      await fetchCampaignAnalysis({
        campaignId: String(campaign.id),
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
      }).unwrap();
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      await refreshCampaign();
    }, 5000);

    return () => clearInterval(interval);
  }, [refreshCampaign]);

  if (!campaign || !targetPageUrl) {
    return null;
  }

  return (
    <Fragment>
      {isAddKeywordModalOpen && (
        <AddKeywordModal isOpen={isAddKeywordModalOpen} onConfirm={handleAddKeyword} onClose={() => toggleAddKeywordModal(false)} targetPage={targetPageUrl.targetPage} />
      )}
      {isRefineCompetitorsModalOpen && (
        <CompetitorsModal
          targetPageId={targetPageId}
          isOpen={isRefineCompetitorsModalOpen}
          onClose={() => toggleRefineCompetitorsModal(false)}
          onConfirm={handleCompetitorsConfirm}
        />
      )}
      <Page>
        <Page.TopBar className='whitespace-nowrap' goBack={goBack} title={targetPageUrl.targetPage} />
        <Page.Body>
          <main className='space-y-4'>
            <section className='flex items-center justify-between pb-4 xl:pb-2'>
              <div className='flex flex-row flex-nowrap items-baseline gap-2'>
                <h2 className='mr-2 font-semibold'>Analysis</h2>
              </div>
              <div className='flex gap-5'>
                <Button onClick={() => toggleRefineCompetitorsModal(true)} variant='outline-light' className='h-9 whitespace-nowrap font-bold'>
                  Refine Competitors
                </Button>
                {isLoading || isFetching ? (
                  <Skeleton className='h-9 w-40' />
                ) : (
                  <Button
                    onClick={() => toggleAddKeywordModal(true)}
                    isLoading={isLoadingAddURL || isLoading}
                    loadingText={`${round(campaign.fetch_progress * 100, 0)} %`}
                    className='h-9 whitespace-nowrap'
                    disabled={isLoadingAddURL}
                  >
                    Add Keyword
                  </Button>
                )}
              </div>
            </section>
            <section>
              <Suspense fallback={<AnalysisCardListSkeleton />}>
                <Await resolve={data}>
                  <AnalysisCardList analysisData={targetPageUrl} />
                </Await>
              </Suspense>
            </section>
            <section>
              <KeywordsAnalysisTable />
            </section>
          </main>
        </Page.Body>
      </Page>
    </Fragment>
  );
};

export { IndividualTargetUrlPageLoader };
export default IndividualTargetUrlPage;
