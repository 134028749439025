const {
  VITE_APP_STAGE,
  VITE_BASE_API,
  VITE_BASE_POC_API,
  VITE_BUGSNAG_KEY,
  VITE_WS_URL,
  VITE_GOOGLE_CLIENT_ID,
  VITE_STRIPE_PUBLISHABLE_KEY,
  VITE_FULLSTORY_ID,
  VITE_FULLSTORY_ENABLED,
  VITE_AI_ENABLED,
  VITE_APP_VERSION,
  VITE_LIR_CONNECTION_ENABLED,
} = process.env;

const BASE_API_URL = VITE_BASE_API;
const BASE_POC_API_URL = VITE_BASE_POC_API;
const WS_URL = VITE_WS_URL;
const BUGSNAG_KEY = VITE_BUGSNAG_KEY || 'VITE_BUGSNAG_KEY environment value not set!';
const GOOGLE_CLIENT_ID = VITE_GOOGLE_CLIENT_ID;
const STRIPE_PUBLISHABLE_KEY = VITE_STRIPE_PUBLISHABLE_KEY || '';
const FULLSTORY_ID = VITE_FULLSTORY_ID || '';
const FULLSTORY_ENABLED = VITE_FULLSTORY_ENABLED === 'true';
const AI_ENABLED = VITE_AI_ENABLED === 'true';
const APP_STAGE = VITE_APP_STAGE || 'local';
const APP_VERSION = VITE_APP_VERSION || '?';
const LIR_CONNECTION_ENABLED = VITE_LIR_CONNECTION_ENABLED === 'true';

export {
  AI_ENABLED,
  APP_STAGE,
  APP_VERSION,
  BASE_API_URL,
  BASE_POC_API_URL,
  BUGSNAG_KEY,
  FULLSTORY_ENABLED,
  FULLSTORY_ID,
  GOOGLE_CLIENT_ID,
  LIR_CONNECTION_ENABLED,
  STRIPE_PUBLISHABLE_KEY,
  WS_URL,
};
