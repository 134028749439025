import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';

const getQueryArgs = (getState: () => RootState<any, any, 'api'>, endpointName: string) => {
  return Object.values(getState().api.queries).find((query) => query?.endpointName === endpointName && query.status !== 'uninitialized');
};

const getPOCQueryArgs = (getState: () => RootState<any, any, 'apiPoc'>, endpointName: string) => {
  return Object.values(getState().apiPoc.queries).find((query) => query?.endpointName === endpointName && query.status !== 'uninitialized');
};

export { getQueryArgs, getPOCQueryArgs };
