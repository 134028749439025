import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import Papa, { ParseStepResult } from 'papaparse';
import ProgressBar from '../ProgressBar';
import { CheckIcon, CloudArrowUpIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import Button from '../Button';

interface UploadFieldProps {
  onUpload: (results: Array<UploadedCombination>) => void;
  onDelete?: () => void;
  showProgress?: boolean;
  title: string;
}

const UploadField: FC<UploadFieldProps> = ({ title, onUpload, showProgress = false, onDelete }) => {
  const [fileValue, setFileValue] = useState<File>();
  const [progress, setProgress] = useState(0);
  const [csvResults, setCsvResults] = useState<Array<UploadedCombination>>([]);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (progress === 100) {
      onUpload(csvResults);
    }
  }, [csvResults, onUpload, progress]);

  useEffect(() => {
    if (fileValue) {
      let resultProgress = 0;
      const reader = new FileReader();
      reader.onload = (_) => {
        const results = reader.result as string;
        const linesCount = results.split('\n').length;

        Papa.parse(results, {
          step: (row: ParseStepResult<UploadedCombination>) => {
            resultProgress++;

            setProgress((resultProgress * 100) / linesCount);
            setCsvResults((old) => [...old, row.data]);
          },
        });
      };

      setFileName(fileValue.name);
      reader.readAsText(fileValue);
    }
  }, [fileValue]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      return null;
    }

    setFileValue(event.target.files[0]);
  };

  const handleDeleteFile = useCallback<MouseEventHandler<SVGElement>>(
    (event) => {
      event.preventDefault();
      setFileValue(undefined);
      setFileName('');
      setCsvResults([]);
      setProgress(0);

      onDelete && onDelete();
    },
    [onDelete],
  );

  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.click();
    }
  };

  return (
    <div>
      <label htmlFor='file'>
        {progress === 0 && (
          <Button className='flex flex-nowrap items-center gap-2' variant='outline-light' onClick={handleUploadClick}>
            <CloudArrowUpIcon className='w-6' />
            <p className='whitespace-nowrap text-center'>{title}</p>
            <input id='file' type='file' accept='.csv' onChange={changeHandler} className='hidden' ref={uploadButtonRef} />
          </Button>
        )}
        {showProgress && progress !== 0 && (
          <div className='flex flex-col flex-nowrap'>
            <div className='flex flex-row flex-nowrap items-center justify-between px-2'>
              <div className='flex w-full items-center justify-between'>
                <div className='flex flex-row items-center'>
                  <p>{fileName !== '' ? fileName : 'uploading file...'}</p>
                  {progress === 100 && <CheckIcon className='h-6 w-6 text-green-500' />}
                </div>
                {progress === 100 && <TrashIcon className='ml-auto h-6 w-6 cursor-pointer' onClick={handleDeleteFile} />}
              </div>
            </div>
            <ProgressBar progress={progress} className='w-[300px]' />
          </div>
        )}
      </label>
    </div>
  );
};

export default UploadField;
