import { BASE_POC_API_URL } from '@/constants/application';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { getQueryArgs } from '@/utils/query';
import { deserializeUser } from '../users/users.api';
import api from '../api';

const lcrAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getURLList: build.query<GetURLListResponse<LCRURL<User>>, POCPagination>({
      query: ({ page }) => ({
        method: 'GET',
        url: 'lcr/url/',
        params: {
          page,
        },
      }),
      transformResponse: (data: GetURLListResponse<LCRURL<UserJSON>>) => {
        const results = data.results.map((url) => {
          if (url.user) {
            return { ...url, user: deserializeUser(url.user) };
          }

          return { ...url, user: null };
        });

        return { ...data, results };
      },
    }),
    createURLAudience: build.mutation<LCRURL<User>, CreateURLAudienceFormValues>({
      query: ({ url }) => ({
        method: 'POST',
        url: 'lcr/url/',
        body: {
          url,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const newURL = await queryFulfilled;
          const queryArgs = getQueryArgs(getState, 'getURLList');

          if (queryArgs && queryArgs.originalArgs) {
            dispatch(
              lcrAPI.util.updateQueryData('getURLList', queryArgs.originalArgs as POCPagination, (draft) => {
                return { count: draft.count, results: [newURL.data, ...draft.results] };
              }),
            );
          }
        } catch {}
      },
      transformResponse: (data: LCRURL<UserJSON>) => {
        if (data.user) {
          return { ...data, user: deserializeUser(data.user) };
        }

        return { ...data, user: null };
      },
    }),
  }),
});

const apiPOC = createApi({
  reducerPath: 'apiPoc',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_POC_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (build) => ({
    getURLDetail: build.query<LCRURL, string>({
      query: (urlId) => ({
        method: 'GET',
        url: `url/${urlId}/`,
      }),
    }),
    getURLTargetAudience: build.query<Array<URLTargetAudience>, string>({
      query: (urlId) => ({
        method: 'GET',
        url: `url/${urlId}/question/`,
      }),
    }),
    createURLSummary: build.mutation<void, string>({
      query: (urlId) => ({
        method: 'POST',
        url: `url/${urlId}/summary/`,
      }),
    }),
  }),
});

const { useGetURLTargetAudienceQuery, useLazyGetURLTargetAudienceQuery, useGetURLDetailQuery, useLazyGetURLDetailQuery, useCreateURLSummaryMutation } = apiPOC;

const { useGetURLListQuery, useLazyGetURLListQuery, useCreateURLAudienceMutation } = lcrAPI;

export {
  useGetURLListQuery,
  useLazyGetURLListQuery,
  useCreateURLAudienceMutation,
  useGetURLTargetAudienceQuery,
  useLazyGetURLTargetAudienceQuery,
  useGetURLDetailQuery,
  useLazyGetURLDetailQuery,
  useCreateURLSummaryMutation,
};

export default apiPOC;
