import cn from '@/utils/style';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { FC, PropsWithChildren } from 'react';

interface PillProps extends PropsWithChildren {
  onClick?: () => void;
  className?: string;
  showClose?: boolean;
}

const Pill: FC<PillProps> = ({ children, onClick, className, showClose = true }) => {
  return (
    <div
      onClick={onClick}
      className={cn(className, onClick && 'cursor-pointer', 'flex items-center justify-center gap-1 rounded-full border border-gray-300 bg-gray-200 px-2 py-1 shadow-lg')}
    >
      {children}
      {showClose && <XMarkIcon className='h-4 w-4 text-gray-500' />}
    </div>
  );
};

export default Pill;
