import cn from '@/utils/style';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ChangeEvent, Fragment, useCallback } from 'react';
import Label from '../Label';

export interface AutocompleteProps<T> {
  value: T;
  options: Array<OptionType<T>>;
  onSelect: (value: T) => void;
  onSearch: (value: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  withMarker?: boolean;
  optionsClassName?: string;
  disabled?: boolean;
  creatableButton?: JSX.Element | null;
}

const Autocomplete = <T extends unknown>({
  options,
  value,
  onSelect,
  onSearch,
  label,
  className = '',
  withMarker = false,
  optionsClassName = '',
  disabled = false,
  creatableButton,
}: AutocompleteProps<T>) => {
  const getSelectedLabel = useCallback(
    (value: T) => {
      const opt = options.find((opt) => opt.value === value);
      return opt ? opt.label : '';
    },
    [options],
  );

  const renderOptions = (option: { label: string; value: T }) => {
    const renderContent = (selected: boolean) => (
      <>
        <p className={cn('z-10 block font-normal', selected && 'font-medium')}>{String(option.label)}</p>
        {withMarker && selected && (
          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-500'>
            <CheckIcon className='h-5 w-5' />
          </span>
        )}
      </>
    );

    return (
      <Combobox.Option
        key={String(option.value)}
        value={option.value}
        className={({ active }) => cn('relative border-b p-2 text-gray-900', withMarker && 'pl-10 pr-4', active && 'bg-gray-200')}
      >
        {({ selected }) => renderContent(selected)}
      </Combobox.Option>
    );
  };

  return (
    <div className='relative flex flex-col gap-2'>
      {label && <Label>{label}</Label>}
      <Combobox value={value} onChange={onSelect} disabled={disabled}>
        <div>
          <div className='relative'>
            <Combobox.Input
              displayValue={() => getSelectedLabel(value)}
              onChange={onSearch}
              className={cn(
                'w-full rounded-lg border border-gray-200 bg-white p-2 py-[10px] outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2',
                disabled && 'bg-gray-200 hover:border-gray-200',
                className,
              )}
            />
            <Combobox.Button className={'absolute inset-y-0 right-0 flex w-full items-center justify-end pr-2'}>
              <ChevronUpDownIcon className='h-5 w-5 text-gray-400' />
            </Combobox.Button>
          </div>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Combobox.Options
              className={cn(
                'absolute z-50 mt-1 block max-h-60 w-full cursor-pointer overflow-auto rounded-lg bg-white p-2 py-1 text-base ring-1 ring-black ring-opacity-5',
                optionsClassName,
              )}
            >
              {options.map(renderOptions)}
              {creatableButton && <>{creatableButton}</>}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default Autocomplete;
