import { Modal } from '@/components';
import store from '@/store';
import campaignAPI from '@/store/campaign/campaign.api';
import { Suspense, useMemo } from 'react';
import { ActionFunction, Await, LoaderFunction, SubmitOptions, defer, redirect, useLoaderData, useNavigate, useNavigation, useParams, useSubmit } from 'react-router-dom';
import RefineKeywordSkeleton from './RefineKeywordSkeleton';
import { RefineKeywordsContent } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { selectKeywords } from '@/store/campaign/campaign.selector';
import { setIsDirty, setKeywords } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';

type KeywordActionData = {
  keywords: string;
};

const refineKeywordLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as AnalyisisPageParams;
  const refineKwsPromise = store.dispatch(campaignAPI.endpoints.getRefineKeywords.initiate({ campaignId }));

  try {
    return defer({ data: refineKwsPromise.unwrap() });
  } catch (e) {
    return {
      campaignId,
      error: e as APIError,
    };
  } finally {
    refineKwsPromise.unsubscribe();
  }
};

const refineKeywordAction: ActionFunction = async ({ params, request }) => {
  const { campaignId } = params as AnalyisisPageParams;
  const dt = await request.formData();
  const dtJson = Object.fromEntries(dt) as KeywordActionData;

  store.dispatch(setIsDirty({ step: 'analysis', isDirty: true }));
  const kws = JSON.parse(dtJson.keywords);
  await store.dispatch(campaignAPI.endpoints.updateRefineKeywords.initiate({ campaignId, keywords: kws }));
  await store.dispatch(
    campaignAPI.endpoints.getCampaignAnalysis.initiate(
      {
        campaignId,
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
      },
      { forceRefetch: true },
    ),
  );

  return redirect(`/campaigns/${campaignId}/analysis?refresh=true`);
};

const RefineKeywords = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const { data, error } = useLoaderData() as PageLoaderDefData<APIListResponse<RefineCampaignKeyword>>;
  const dispatch = useDispatch<AppDispatch>();
  const navigator = useNavigation();
  const navigate = useNavigate();
  const submit = useSubmit();
  const keywords = useSelector(selectKeywords);

  const isSubmitting = useMemo(() => ['submitting', 'loading'].includes(navigator.state), [navigator.state]);

  const errorMessage = `something went wrong, ${error?.status} - ${error?.data.message}`;
  const submitAction: SubmitOptions = { method: 'post', action: `/campaigns/${campaignId}/analysis/refine-keywords` };

  const handleOnClose = async () => {
    const defaultData = await data;
    dispatch(setKeywords(defaultData.results));
    navigate('..?refresh=false');
  };

  const handleConfirm = () => {
    if (!keywords.length) {
      return;
    }
    submit({ keywords: JSON.stringify(keywords) }, submitAction);
  };

  return (
    <Suspense fallback={<RefineKeywordSkeleton />}>
      <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
        <Modal isOpen title='Keywords' dialogPanelclassName='2xl:w-[80%] 2xl:max-w-[80%]' onClose={handleOnClose} onConfirm={handleConfirm} isLoading={isSubmitting}>
          <RefineKeywordsContent isLoading={isSubmitting} />
        </Modal>
      </Await>
    </Suspense>
  );
};

export default RefineKeywords;
export { refineKeywordAction, refineKeywordLoader };
