const TOP_BAR_HEIGHT = 72;
const ANALYSIS_TOP_BAR_HEIGHT = 107;
const FOOTER_HEIGHT = 70;
const LAYOUT_PADDING = 28;

const SCREEN_SIZES = {
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  max: 1920,
};

const FONT_SIZES = {
  sm: 14,
  base: 16,
} as const;

const NO_TABLE_SIZE = 56;
const CAMPAIGN_NO_TABLE_HEIGHT = TOP_BAR_HEIGHT + NO_TABLE_SIZE + LAYOUT_PADDING * 2;

export { TOP_BAR_HEIGHT, ANALYSIS_TOP_BAR_HEIGHT, FOOTER_HEIGHT, LAYOUT_PADDING, SCREEN_SIZES, NO_TABLE_SIZE, CAMPAIGN_NO_TABLE_HEIGHT, FONT_SIZES };
