import { Button, Checkbox, Modal, ModalProps, SearchBarDebounce, Select, VirtualizedTable } from '@/components';
import { useScreenSize } from '@/hooks/screenSize';
import { useTable } from '@/hooks/table';
import { useGetTopKeywordsQuery } from '@/store/campaign/campaign.api';

import { createColumnHelper } from '@tanstack/react-table';
import { useField } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';

type PullTopKeywordsModalProps = ModalProps & {
  onConfirm: () => void;
  field: string;
};

const ModalFooter = ({ onConfirm, disabled }: { onConfirm: () => void; disabled: boolean }) => {
  return (
    <Button onClick={onConfirm} disabled={disabled} className='ml-auto'>
      Confirm
    </Button>
  );
};

type PullTopKeywordsColumn = {
  keyword: string;
  volume: number;
  gap: number;
};

const formatTopKeywords = (topKeywords: TopKeywords[]) => {
  return topKeywords.map((keyword) => ({
    keyword: keyword.keyword,
    volume: keyword.searchVolume,
    // gap is a placeholder for now, we currently don't know what is going to be here
    gap: keyword.searchVolume,
  }));
};

const columnHelper = createColumnHelper<PullTopKeywordsColumn>();

const keywordSearchTypesOptions: Array<OptionType<KeywordSearchType>> = [
  { value: 'exact-url', label: 'Exact URL' },
  { value: 'domain', label: 'Root Domain' },
  { value: 'subdomain', label: 'Subdomain' },
  { value: 'subfolder', label: 'Subfolder' },
];

const PullTopKeywordsModal: FC<PullTopKeywordsModalProps> = ({ onClose, onConfirm, field }) => {
  const [{ value: keywordCount }] = useField<number>('keyword_count');
  const [{ value: fieldKeywords }, , keywordsHelper] = useField<string[]>(`${field}.keywords`);
  const [countryField] = useField<string>('country');
  const country = useMemo(() => countryField.value || 'us', [countryField]);
  const [urlField] = useField<string>(`${field}.url`);
  const [tableSearchFilter, setTableSearchFilter] = useState('');
  const [searchType, setSearchType] = useState<KeywordSearchType>('exact-url');
  const {
    data: topKeywords,
    isLoading,
    isFetching,
  } = useGetTopKeywordsQuery({ url: urlField.value, country, limit: keywordCount, keyword_containing: tableSearchFilter, search_type: searchType });

  const formattedKeywords = useMemo(() => {
    if (topKeywords) {
      return formatTopKeywords(topKeywords.results);
    }
    return [];
  }, [topKeywords]);

  const { width } = useScreenSize();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />,
        cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />,
        size: 45,
      }),
      columnHelper.accessor('keyword', {
        header: () => <p className='text-left'>Keyword</p>,
        cell: ({ getValue }) => <p className='line-clamp-1 text-ellipsis break-all'>{getValue()}</p>,
      }),
      columnHelper.accessor('volume', {
        header: () => <p className='text-left'>Volume</p>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('gap', {
        header: () => <p className='text-left'>Gap</p>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
    ],
    [],
  );

  const [table, rows] = useTable({
    data: formattedKeywords,
    columns,
    tableCustomOptions: ['allowSelect', 'allowFilters', 'allowResize', 'allowSort'],
    tableOptions: {
      meta: {
        size: (width * 0.557) / columns.length,
      },
    },
  });

  const { flatRows } = table.getSelectedRowModel();

  useEffect(() => {
    rows.forEach((row) => {
      if (fieldKeywords && fieldKeywords.includes(row.original.keyword)) {
        row.toggleSelected(true);
      }
    });
  }, [fieldKeywords, rows]);

  const handleConfirm = () => {
    const keywords = flatRows.map((row) => row.original.keyword);
    keywordsHelper.setValue([...fieldKeywords, ...keywords]);
    onConfirm();
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      title='Pull Top Keyword'
      footer={<ModalFooter onConfirm={handleConfirm} disabled={flatRows.length === 0} />}
      dialogPanelclassName='2xl:w-[60%] 2xl:max-w-[60%]'
    >
      <div className='mb-2 flex w-full items-center justify-between'>
        <SearchBarDebounce
          delay={750}
          className='w-5/6'
          isFetching={isLoading || isFetching}
          inputClassName='h-10 w-full rounded-md bg-slate-100'
          onChange={(val) => setTableSearchFilter(val)}
        />
        <Select className='w-full' value={searchType} onChange={(selection) => setSearchType(selection)} options={keywordSearchTypesOptions} />
      </div>
      <div className='max-h-96 overflow-scroll'>
        <VirtualizedTable table={table} name='pullTopKeywordsModal' allowResize isLoading={isLoading || isFetching} loadingText='Loading Top Keywords...' />
      </div>
    </Modal>
  );
};

export default PullTopKeywordsModal;
