import { FC } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { ErrorFallback } from './components';
import { RequireAuth, SidebarLayout } from './layouts';
import { authLoader } from './layouts/RequireAuth';
import {
  AnalysisPage,
  AnalysisPresentationPage,
  AnchorTextGeneratorPage,
  AnchorTextResultsPage,
  Dashboard,
  FinalizeSectionPage,
  ForgotPasswordPage,
  Login,
  NewCampaign,
  ProfilePage,
  RefineKeywords,
  ResetPasswordPage,
  ResetPasswordSuccessPage,
  ScenarioReviewPage,
  SettingsPage,
  UserInvitationPage,
  UserManagementPage,
  analysisPageAction,
  analysisPageLoader,
  analysisPresentationPageLoader,
  finalizeSectionPageLoader,
  loginLoader,
  newCampaignLoader,
  refineKeywordLoader,
  resetPasswordPageLoader,
  scenarioReviewLoader,
  userInvitationPageLoader,
  usersPageLoader,
  UsersPage,
  CompaniesPage,
  deleteCompanyAction,
  dashboardLoader,
  IndividualTargetUrlPage,
  Tour,
  URLPage,
  URLDetailPage,
  linkingContextReportLoader,
  URLDetailPageLoader,
} from './pages';
import { IndividualTargetUrlPageLoader, anchorTextGeneratorPageLoader } from './pages/Campaigns';
import { refineKeywordAction } from './pages/Campaigns/pages/AnalysisPage/pages/RefineKeywords/RefineKeywords';
import { BasicSettings, PaymentSettings } from './pages/SettingsPage/pages';
import store from './store';
import { BUGSNAG_KEY, APP_STAGE, APP_VERSION } from './constants/application';

import BugsnagPerformance from '@bugsnag/browser-performance';
import { ReactRouterRoutingProvider } from '@bugsnag/react-router-performance';
import { BrowserConfiguration } from '@bugsnag/browser-performance/dist/types/config';
import AccessRequestsPage from './pages/UserManagementPage/pages/AccessRequests';

const routes = [
  {
    path: 'login',
    errorElement: <ErrorFallback />,
    element: <Login />,
    loader: loginLoader,
  },
  {
    errorElement: <ErrorFallback />,
    element: <RequireAuth />,
    loader: authLoader,
    path: '/',
    children: [
      {
        errorElement: <ErrorFallback />,
        element: <SidebarLayout />,
        children: [
          {
            path: '/',
            errorElement: <ErrorFallback />,
            element: <Dashboard />,
            loader: dashboardLoader,
            children: [
              {
                path: 'tour',
                errorElement: <ErrorFallback />,
                element: <Tour />,
              },
            ],
          },
          {
            path: 'new-campaign',
            errorElement: <ErrorFallback />,
            element: <NewCampaign />,
            loader: newCampaignLoader,
          },
          {
            path: 'campaigns/:campaignId',
            errorElement: <ErrorFallback />,
            children: [
              {
                path: 'analysis/:targetPageId',
                element: <IndividualTargetUrlPage />,
                loader: IndividualTargetUrlPageLoader,
              },
              {
                path: 'analysis',
                element: <AnalysisPage />,
                errorElement: <ErrorFallback />,
                loader: analysisPageLoader,
                action: analysisPageAction,
                shouldRevalidate: ({ nextUrl, currentUrl }: { nextUrl: URL; currentUrl: URL }) => {
                  const hasKeywords = store.getState().campaign.keywords.length > 0;
                  const refreshSP = currentUrl.searchParams.get('refresh');
                  const nextIsRefine = nextUrl.pathname.includes('refine-keywords');

                  return !nextIsRefine && refreshSP === 'true' && !hasKeywords;
                },
                children: [
                  {
                    path: 'refine-keywords',
                    element: <RefineKeywords />,
                    errorElement: <ErrorFallback />,
                    loader: refineKeywordLoader,
                    action: refineKeywordAction,
                  },
                ],
              },
              {
                path: 'scenario-review',
                element: <ScenarioReviewPage />,
                loader: scenarioReviewLoader,
                errorElement: <ErrorFallback />,
              },
              {
                path: 'anchor-text-generator',
                element: <AnchorTextGeneratorPage />,
                errorElement: <ErrorFallback />,
                loader: anchorTextGeneratorPageLoader,
              },
              {
                path: 'anchor-text-results',
                element: <AnchorTextResultsPage />,
                errorElement: <ErrorFallback />,
                loader: anchorTextGeneratorPageLoader,
              },
              {
                path: 'summary',
                element: <FinalizeSectionPage />,
                errorElement: <ErrorFallback />,
                loader: finalizeSectionPageLoader,
              },
            ],
          },
          {
            path: 'user-management',
            errorElement: <ErrorFallback />,
            element: <UserManagementPage />,
            children: [
              {
                path: 'companies',
                errorElement: <ErrorFallback />,
                children: [
                  {
                    index: true,
                    errorElement: <ErrorFallback />,
                    element: <CompaniesPage />,
                  },
                  {
                    path: 'edit-company/:companyId',
                    errorElement: <ErrorFallback />,
                    element: <CompaniesPage />,
                  },
                  {
                    path: 'delete-company/:companyId',
                    errorElement: <ErrorFallback />,
                    element: <CompaniesPage />,
                    action: deleteCompanyAction,
                  },
                ],
              },
              {
                path: 'users',
                errorElement: <ErrorFallback />,
                children: [
                  {
                    index: true,
                    errorElement: <ErrorFallback />,
                    element: <UsersPage />,
                    loader: usersPageLoader,
                  },
                  {
                    path: 'new-user',
                    errorElement: <ErrorFallback />,
                    element: <UsersPage />,
                    loader: usersPageLoader,
                  },
                ],
              },
              {
                path: 'access-requests',
                errorElement: <ErrorFallback />,
                children: [
                  {
                    index: true,
                    errorElement: <ErrorFallback />,
                    element: <AccessRequestsPage />,
                  },
                ],
              },
            ],
          },
          {
            path: 'settings',
            errorElement: <ErrorFallback />,
            element: <SettingsPage />,
            children: [
              {
                index: true,
                path: 'general',
                errorElement: <ErrorFallback />,
                element: <BasicSettings />,
              },
              {
                path: 'payment',
                errorElement: <ErrorFallback />,
                element: <PaymentSettings />,
              },
            ],
          },
          {
            path: 'linking-context-report',
            errorElement: <ErrorFallback />,
            element: <Outlet />,
            loader: linkingContextReportLoader,
            children: [
              {
                index: true,
                errorElement: <ErrorFallback />,
                element: <URLPage />,
                // loader: TODO
              },
              {
                path: 'url/:urlId/detail',
                errorElement: <ErrorFallback />,
                element: <URLDetailPage />,
                loader: URLDetailPageLoader,
              },
            ],
          },
          {
            path: 'profile',
            element: <ProfilePage />,
            errorElement: <ErrorFallback />,
            // loader: TODO
          },
        ],
      },
      {
        path: 'presentation',
        children: [
          {
            path: ':campaignId',
            errorElement: <ErrorFallback />,
            element: <AnalysisPresentationPage />,
            loader: analysisPresentationPageLoader,
          },
          {
            path: ':campaignId/:term',
            errorElement: <ErrorFallback />,
            element: <AnalysisPresentationPage />,
            loader: analysisPresentationPageLoader,
          },
        ],
      },
    ],
  },
  {
    path: 'forgot-password',
    errorElement: <ErrorFallback />,
    element: <ForgotPasswordPage />,
  },
  {
    path: 'user-invitation/:uid',
    errorElement: <ErrorFallback />,
    element: <UserInvitationPage />,
    loader: userInvitationPageLoader,
  },
  {
    path: 'reset-password/:uid',
    errorElement: <ErrorFallback />,
    element: <ResetPasswordPage />,
    loader: resetPasswordPageLoader,
  },
  {
    path: 'reset-password-success',
    errorElement: <ErrorFallback />,
    element: <ResetPasswordSuccessPage />,
  },
  {
    path: '*',
    element: <Navigate to='/' replace />,
    errorElement: <ErrorFallback />,
  },
];

const router = createBrowserRouter(routes);

BugsnagPerformance.start({
  apiKey: BUGSNAG_KEY,
  routingProvider: new ReactRouterRoutingProvider(routes),
  releaseStage: APP_STAGE,
  enabledReleaseStages: ['production', 'staging', 'development'],
  appVersion: APP_VERSION,
  networkRequestCallback: (requestInfo) => {
    if (['script', 'iframe'].includes(requestInfo.type)) {
      return null;
    }

    if (['fullstory', '127.0.0.1', 'localhost'].some((i) => requestInfo.url && requestInfo.url.includes(i))) {
      return null;
    }

    return requestInfo;
  },
  settleIgnoreUrls: [],
} as BrowserConfiguration);

const App: FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
