import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { memo, useMemo } from 'react';

interface TooltipContentProps<T> {
  data: T;
  title: string;
  hidePercentage?: boolean;
  countToScore?: boolean;
}

interface TooltipColumns {
  titles: Array<string>;
  count: Array<number | string>;
  percentage?: Array<string>;
}

const ChartTooltipContent = <T extends Record<string, number>>({ data, title, hidePercentage = false, countToScore = false }: TooltipContentProps<T>) => {
  const total = useMemo(() => Object.values(data).reduce((total, val) => total + val, 0), [data]);
  const columns = useMemo(() => {
    const cols: TooltipColumns = {
      titles: [''],
      count: [countToScore ? 'score' : 'count'],
    };

    if (!hidePercentage) {
      cols.percentage = ['(%)'];
    }

    return Object.keys(data).reduce((cols: TooltipColumns, key) => {
      cols.titles.push(key);
      cols.count.push(data[key].toLocaleString());
      cols.percentage?.push(`${round(total ? (data[key] * 100) / total : 0, 2)}%`);

      return cols;
    }, cols);
  }, [data, total, hidePercentage, countToScore]);

  const renderColumn = (col: string, columnIdx: number) => {
    const renderContent = (val: string | number, contentIdx: number) => {
      return (
        <div key={contentIdx} className={cn('flex items-center gap-4', columnIdx !== 0 ? 'justify-end' : 'mr-6')}>
          {columnIdx === 0 && contentIdx !== 0 && (
            <div
              className={cn(
                'h-2 w-2 rounded-full',
                contentIdx === 1 && 'bg-chart-0',
                contentIdx === 2 && 'bg-chart-1',
                contentIdx === 3 && 'bg-chart-2',
                contentIdx === 4 && 'bg-chart-3',
                contentIdx === 5 && 'bg-chart-4',
              )}
            ></div>
          )}
          <p className={cn(contentIdx === 0 && 'h-6')}>{val}</p>
        </div>
      );
    };

    return <div key={columnIdx}>{(columns[col as keyof TooltipColumns] || []).map(renderContent)}</div>;
  };

  return (
    <div>
      <p>{title}</p>
      <div className='flex items-center justify-between gap-2'>{Object.keys(columns).map(renderColumn)}</div>
    </div>
  );
};

export default memo(ChartTooltipContent);
