import { FC, Fragment, Suspense } from 'react';
import { Page } from '@/layouts';
import apiPOC from '@/store/poc/poc.api';
import { Await, defer, LoaderFunction, useLoaderData, useNavigate } from 'react-router-dom';
import { Button } from '@/components';
import { CollapsableCards, TargetAudienceModal } from './components';
import { URLAudienceSkeleton, URLDetailSkeleton, URLDetailTopBarSkeleton } from './URLDetailPageSkeleton';
import { useToggle } from '@/hooks/toggle';
import store from '@/store';

type URLDetailPageData = [LCRURL, URLTargetAudience[]];

const URLDetailPage: FC = () => {
  const navigate = useNavigate();
  const { data, error } = useLoaderData() as PageLoaderDefData<URLDetailPageData>;
  const { value: isTargetAudienceModalOpen, open, close } = useToggle();
  const errorMessage = `something went wrong, ${error?.status} - ${error?.data.message}`;

  return (
    <Page>
      <Suspense fallback={<URLDetailTopBarSkeleton />}>
        <Await resolve={data}>{([urlDetail]: [LCRURL]) => <Page.TopBar title={urlDetail.url} className='flex items-center' goBack={() => navigate(-1)} />}</Await>
      </Suspense>
      <Page.Body>
        <Suspense fallback={<URLDetailSkeleton />}>
          <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
            {([urlDetail]: [LCRURL]) => (
              <div className='columns-2 rounded-leap bg-white p-4 text-left'>
                <div>
                  <h4 className='mb-2'>Page Offering</h4>
                  <p>{urlDetail?.offering}</p>
                </div>
                <div>
                  <h4 className='mb-2'>Buyer Wants to Achieve</h4>
                  <p>{urlDetail?.buyer_wants_to_achieve}</p>
                </div>
              </div>
            )}
          </Await>
        </Suspense>
        <div className='my-10 flex items-center justify-between text-left'>
          <h4>Target Audience</h4>
          <Button onClick={() => open()}>All Target Audience Questions</Button>
        </div>
        <Suspense fallback={<URLAudienceSkeleton />}>
          <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
            {([, audiences]: [unknown, URLTargetAudience[]]) => (
              <Fragment>
                <CollapsableCards audiences={audiences || []} />
                <TargetAudienceModal isOpen={isTargetAudienceModalOpen} onClose={close} audiences={audiences || []} />
              </Fragment>
            )}
          </Await>
        </Suspense>
      </Page.Body>
    </Page>
  );
};

const URLDetailPageLoader: LoaderFunction = async ({ params }) => {
  const { urlId } = params as { urlId: string };

  const URLDetailPromise = store.dispatch(apiPOC.endpoints.getURLDetail.initiate(urlId));
  const targetAudiencePromise = store.dispatch(apiPOC.endpoints.getURLTargetAudience.initiate(urlId));

  try {
    const data = Promise.all([URLDetailPromise.unwrap(), targetAudiencePromise.unwrap()]);

    return defer({ data });
  } catch (e) {
    return {
      error: e as APIError,
    };
  } finally {
    URLDetailPromise.unsubscribe();
    targetAudiencePromise.unsubscribe();
  }
};

export default URLDetailPage;

export { URLDetailPageLoader };
