import { BellIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { SideMenuItem } from '../SideBar/components';

const NotificationButton: FC<{ isSidebarOpen: boolean; hasPendingNotifications?: boolean }> = ({ isSidebarOpen, hasPendingNotifications }) => {
  return (
    <div className='relative'>
      <SideMenuItem isSidebarOpen={isSidebarOpen} Icon={BellIcon} title='Notifications' />
      {hasPendingNotifications && <ExclamationCircleIcon className='absolute left-2 top-2 w-4 text-red-500' />}
    </div>
  );
};

export default NotificationButton;
